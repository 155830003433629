<template>
  <div>
    <div class="container-fluid center-max-1320-px p-3">
      <div class="row flex-nowrap justify-content-between align-items-start align-self-center">
        <div class="col-md-6 d-flex justify-content-start payment-header-info">
          {{ instance?.name }}
        </div>
        <div class="col-md-6 d-flex justify-content-end payment-header-info">PRICE</div>
      </div>
    </div>
  </div>
  <div>
    <div class="container-fluid center-max-1320-px p-3 second-background payment-licence">
      <div class="row flex-nowrap justify-content-between align-items-start align-self-center">
        <div class="col-8">
          <h4>{{ instance?.name.toUpperCase() }} VPN - LICENSE KEY</h4>
          <p class="text-left">
            <template v-if="billingModel === 'PAID_TRIAL'">
              <template v-if="!instanceAttributes.showOnlyNoticeWithoutTariffs">
                This is a {{ subscriptionPlan?.trialLength }}-days
                {{ initialPeriodName.toLowerCase() }} for only {{ subscriptionPlan?.currency }}
                {{ subscriptionPlan?.initialTariff }} Once the
                {{ initialPeriodName.toLowerCase() }} period has ended, the service will cost
                {{ subscriptionPlan?.currency }} {{ subscriptionPlan?.recurringTariff }} every
                {{ subscriptionPlan?.daysInterval }} days until you stop the service. You can cancel
                your subscription at any moment via the portal.
              </template>
              <template v-else>
                * You always have a {{ subscriptionPlan?.trialLength }} day notice on any membership
                and the membership will expire at the end of the prepaid date,
                as listed in your signup information.
              </template>
            </template>
            <template v-if="billingModel === 'NO_TRIAL'">
              The service will cost {{ subscriptionPlan?.currency }}
              {{ subscriptionPlan?.recurringTariff }} every
              {{ subscriptionPlan?.daysInterval }} days until you stop the service. You can cancel
              your subscription at any moment via the portal.
            </template>
          </p>
        </div>
        <div class="col-4">
          <h4 class="text-right">
            {{ subscriptionPlan?.currency }}
            <template v-if="billingModel === 'PAID_TRIAL'">{{
              subscriptionPlan?.initialTariff
            }}</template>
            <template v-if="billingModel === 'NO_TRIAL'">{{
              subscriptionPlan?.recurringTariff
            }}</template>
          </h4>
          <p v-if="billingModel === 'PAID_TRIAL'" class="text-right">
            ({{ subscriptionPlan?.trialLength }}-days {{ initialPeriodName.toLowerCase() }})
          </p>
        </div>
      </div>

      <div class="row flex-nowrap">
        <div class="col d-flex justify-content-start">
          <select
            id="currency"
            v-model="selectedCurrency"
            autocomplete="on"
            class="payment-form-control currency-select"
            @change="updateCurrency"
          >
            <option v-for="currency in availableCurrencies" :key="currency" :value="currency">
              {{ currency }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="!areTheSameBillingModel" class="row flex-nowrap">
        <div class="col d-flex justify-content-start">
          <select
            id="billing-model"
            v-model="billingModel"
            class="payment-form-control billing-model-select"
            autocomplete="on"
            style="margin-bottom: 10px"
            @change="updateBillingModel"
          >
            <option v-for="billing in availableBillingModels" :key="billing" :value="billing">
              {{ billing === 'PAID_TRIAL' ? `${initialPeriodName} membership` : 'Full membership' }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="availableTrailLengthForSelectedCurrency.length > 1" class="row flex-nowrap">
        <div class="col d-flex justify-content-start">
          <select
            id="trial-length"
            v-model="selectedTrialLength"
            autocomplete="on"
            class="payment-form-control currency-select"
            @change="updateTrialLength"
          >
            <option
              v-for="length in availableTrailLengthForSelectedCurrency"
              :key="length"
              :value="length"
            >
              {{ length }} days
            </option>
          </select>
        </div>
      </div>

      <div
        v-if="availableDaysIntervalForSelectedCurrencyAndBillingModel.length > 1"
        class="row flex-nowrap"
      >
        <div class="col d-flex justify-content-start">
          <select
            id="days-interval"
            v-model="selectedDaysInterval"
            autocomplete="on"
            class="payment-form-control currency-select"
            @change="updateDaysInterval"
          >
            <option
              v-for="length in availableDaysIntervalForSelectedCurrencyAndBillingModel"
              :key="length"
              :value="length"
            >
              {{ length }} days
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-12 d-flex justify-content-end align-items-center">
        <h4>
          TOTAL: {{ subscriptionPlan?.currency }}
          <template v-if="billingModel === 'PAID_TRIAL'">{{
            subscriptionPlan?.initialTariff
          }}</template>
          <template v-if="billingModel === 'NO_TRIAL'">{{
            subscriptionPlan?.recurringTariff
          }}</template>
        </h4>
      </div>
    </div>
  </div>
  <div>
    <div class="container-fluid center-max-1320-px my-3 p-3 second-background">
      <div class="row flex-nowrap justify-content-between align-self-center">
        <div class="col-md-5 d-flex justify-content-start">
          <h4>PAYMENTS METHODS:</h4>
        </div>
        <div class="col-md-7 text-right">
          <img alt="visa image" src="/static/images/visa.png" />
          <img alt="mc image" src="/static/images/mc.png" />
          <img alt="verified visa image" src="/static/images/verified-by-visa.png" />
          <img alt="mc secured image" src="/static/images/mastercard.png" />
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container-fluid center-max-1320-px mt-3 mb-5 p-3 second-background">
      <div class="row flex-sm-nowrap">
        <div class="col-md-6">
          <form @submit.prevent="submitPayment">
            <div class="form-group">
              <input
                id="email"
                v-model="email"
                type="email"
                class="payment-form-control"
                placeholder="Email address"
                required=""
              />

              <div class="row">
                <div class="col">
                  <input
                    id="signup-name"
                    v-model="name"
                    class="payment-form-control"
                    type="text"
                    placeholder="Name"
                    pattern=".{2,}"
                    required=""
                  />
                </div>

                <div class="col">
                  <input
                    id="signup-surname"
                    v-model="surname"
                    class="payment-form-control"
                    type="text"
                    placeholder="Surname"
                    pattern=".{2,}"
                    required=""
                  />
                </div>
              </div>

              <select
                id="locationInput"
                v-model="country"
                name="location"
                class="payment-form-control"
                :required="true"
              >
                <option value="" disabled selected>Select your country</option>
                <option v-for="item in allowedCountries" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>

              <input
                id="signup-address-1"
                v-model="address1"
                class="payment-form-control"
                type="text"
                placeholder="Address - 1st line"
                pattern=".{3,}"
                required=""
              />

              <input
                id="signup-address-2"
                v-model="address2"
                class="payment-form-control"
                type="text"
                placeholder="Address - 2nd line (optional)"
              />

              <div class="row">
                <div class="col">
                  <input
                    id="signup-zip-code"
                    v-model="zipCode"
                    class="payment-form-control"
                    type="text"
                    placeholder="ZIP-code"
                    pattern=".{3,}"
                    required=""
                  />
                </div>

                <div class="col">
                  <input
                    id="signup-city"
                    v-model="city"
                    class="payment-form-control"
                    type="text"
                    placeholder="City"
                    pattern=".{3,}"
                    required=""
                  />
                </div>
              </div>

              <input
                id="signup-phone-number"
                v-model="phoneNumber"
                class="payment-form-control"
                type="text"
                placeholder="Phone Number"
              />

              <div class="text-center">
                <img alt="visa image" src="/static/images/visa.png" />
                <img alt="mc image" src="/static/images/mc.png" />
              </div>

              <input
                id="card-name"
                v-model="cardHolder"
                type="text"
                placeholder="Name on Card"
                pattern=".{3,}"
                title="Minimum 3 characters"
                class="payment-form-control"
                required=""
              />
              <input
                id="card-number"
                v-model="cardNumber"
                type="text"
                placeholder="Credit Card Number"
                maxlength="16"
                pattern="^[245]{1}[0-9]{15}$"
                title="Valid VISA/MC card number"
                class="payment-form-control"
                required=""
              />

              <div class="row">
                <div class="col">
                  <select
                    id="card-month"
                    v-model="cardExpiryMonth"
                    required=""
                    class="payment-form-control"
                  >
                    <option value="" hidden>Month</option>
                    <option
                      v-for="n in 12"
                      :key="n.toString().padStart(2, '0')"
                      :value="n.toString().padStart(2, '0')"
                    >
                      {{ n.toString().padStart(2, '0') }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    id="card-year"
                    v-model="cardExpiryYear"
                    required=""
                    class="payment-form-control"
                  >
                    <option value="" hidden>Year</option>
                    <option
                      v-for="n in 10"
                      :key="new Date().getFullYear() + n - 1"
                      :value="(new Date().getFullYear() + n - 1).toString()"
                    >
                      {{ new Date().getFullYear() + n - 1 }}
                    </option>
                  </select>
                </div>
              </div>
              <input
                id="card-ccv"
                v-model="cardCode"
                type="text"
                class="payment-form-control"
                pattern="[0-9]{3}"
                title="3-digits CVV/CVC code"
                placeholder="Secure Code (CVV/CVC)"
                required=""
              />
              <input type="submit" class="payment-button" value="Download Now" />
              <div class="d-flex payment-form-checkbox-container">
                <input id="infoConfirm1" type="checkbox" name="infoConfirm1" required="" />
                <div class="payment-form-checkbox-info">
                  <template v-if="billingModel === 'PAID_TRIAL'">
                    Purchase Info<br />PAY JUST {{ subscriptionPlan?.currency }}
                    {{ subscriptionPlan?.initialTariff }} TODAY!* By completing this order, I
                    confirm that I am 18 (some locations 21) years or older and agree with the
                    privacy statement and general terms and conditions.
                  </template>
                  <template v-if="billingModel === 'NO_TRIAL'">
                    Purchase Info<br />
                    PAY JUST {{ subscriptionPlan?.currency }}
                    {{ subscriptionPlan?.recurringTariff }} TODAY!* By completing this order, I
                    confirm that I am 18 (some locations 21) years or older and agree with the
                    privacy statement and general terms and conditions.
                  </template>
                </div>
              </div>
              <div class="d-flex payment-form-checkbox-container">
                <input id="infoConfirm2" type="checkbox" name="infoConfirm2" required="" />
                <div class="payment-form-checkbox-info">
                  <template v-if="billingModel === 'PAID_TRIAL'">
                    *After {{ subscriptionPlan?.trialLength }} days You will be charged
                    {{ subscriptionPlan?.currency }} {{ subscriptionPlan?.recurringTariff }} and
                    this charge will recur every {{ subscriptionPlan?.daysInterval }} days.
                  </template>
                  <template v-if="billingModel === 'NO_TRIAL'">
                    *You will be charged {{ subscriptionPlan?.currency }}
                    {{ subscriptionPlan?.recurringTariff }} and this charge will recur every
                    {{ subscriptionPlan?.daysInterval }} days.
                  </template>
                </div>
              </div>
              <div class="d-flex payment-form-checkbox-container">
                <div class="payment-form-checkbox-info">
                  Charges will appear on your card as {{ instance?.supportDomain
                  }}<!-- -->
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-6 d-flex flex-wrap align-items-center">
          <img alt="protect" src="/static/images/devices.svg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { makePayment } from '@/app/api/signup';
import {
  createAndSendForm,
  createIFrameAndResolveMessage,
  handlePrecondition,
} from '@/app/use/payment';
import { redirectToRoute } from '@/app/router';
import { loadScript } from 'vue-plugin-load-script';
import { allowedCountries } from '@/app/helpers/countries';
import { useInstanceStore } from '@/app/stores/instance';
import { storeToRefs } from 'pinia';
import { useTariffStore } from '@/app/stores/tariff';

export default {
  name: 'Payment',
  inheritAttrs: false,
  setup() {
    const instanceStore = useInstanceStore();
    const {
      instance,
      defaultCountry,
      initialPeriodName,
      instanceAttributes,
    } = storeToRefs(instanceStore);

    const tariffStore = useTariffStore();
    const {
      subscriptionPlan,
      billingModel,
      areTheSameBillingModel,
      availableCurrencies,
      availableBillingModels,
      selectedCurrency,
      selectedTrialLength,
      availableTrailLengthForSelectedCurrency,
      selectedDaysInterval,
      availableDaysIntervalForSelectedCurrencyAndBillingModel,
    } = storeToRefs(tariffStore);

    const updateCurrency = event => {
      tariffStore.updateCurrency(event.target.value);
    };

    const updateBillingModel = event => {
      tariffStore.updateBillingModel(event.target.value);
    };

    const updateTrialLength = event => {
      tariffStore.updateTrialLength(event.target.value);
    };

    const updateDaysInterval = event => {
      tariffStore.updateDaysInterval(event.target.value);
    };

    const email = ref(null);
    const cardNumber = ref(null);
    const cardHolder = ref(null);
    const cardCode = ref(null);
    const cardExpiryMonth = ref('');
    const cardExpiryYear = ref('');
    const name = ref(null);
    const surname = ref(null);
    const city = ref(null);
    const country = ref('');
    const zipCode = ref(null);
    const address1 = ref(null);
    const address2 = ref(null);
    const phoneNumber = ref(null);

    const isPaying = ref(false);

    const submitPayment = () => {
      isPaying.value = true;
      const payRequest = {
        email: email.value,
        currency: selectedCurrency.value,
        billingModel: billingModel.value,
        trialLength: selectedTrialLength.value,
        daysInterval: selectedDaysInterval.value,
        billingAddress: {
          name: name.value,
          surname: surname.value,
          country: country.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          zipCode: zipCode.value,
          phoneNumber: phoneNumber.value,
        },
        card: {
          holder: cardHolder.value,
          number: cardNumber.value,
          code: cardCode.value,
          expiry: cardExpiryMonth.value.toString().padStart(2, '0') + cardExpiryYear.value,
        },
        browser: {
          language: navigator.language,
          screenHeight: screen.height,
          screenWidth: screen.width,
          screenColorDepth: screen.colorDepth,
          timezone: new Date().getTimezoneOffset(),
          userAgent: navigator.userAgent,
          javaEnabled: false,
          javaScriptEnabled: true,
        },
      };
      makePayment(payRequest)
        .then(response => {
          handleResponse(response);
        })
        .catch(() => {
          redirectToRoute('/failure');
        })
        .finally(() => (isPaying.value = false));
    };

    const submitPaymentWith3DSData = (transactionId, threeDS) => {
      isPaying.value = true;
      const payRequest = {
        email: email.value,
        currency: selectedCurrency.value,
        billingModel: billingModel.value,
        trialLength: selectedTrialLength.value,
        daysInterval: selectedDaysInterval.value,
        card: {
          holder: cardHolder.value,
          number: cardNumber.value,
          code: cardCode.value,
          expiry: cardExpiryMonth.value.toString().padStart(2, '0') + cardExpiryYear.value,
        },
        browser: {
          language: navigator.language,
          screenHeight: screen.height,
          screenWidth: screen.width,
          screenColorDepth: screen.colorDepth,
          timezone: new Date().getTimezoneOffset(),
          userAgent: navigator.userAgent,
          javaEnabled: false,
          javaScriptEnabled: true,
        },
        threeDS: {
          transactionId: transactionId,
          data: btoa(JSON.stringify(threeDS)),
        },
      };
      makePayment(payRequest)
        .then(response => {
          handleResponse(response);
        })
        .catch(() => {
          redirectToRoute('/failure');
        })
        .finally(() => (isPaying.value = false));
    };

    const handlePendingResponse = response => {
      const threeDSResponse = response.threeDSResponse;

      if (response.gateway === 'NET_VALVE') {
        const transactionId = threeDSResponse.transactionId;
        const url = threeDSResponse.url;
        const isHidden = threeDSResponse.hidden;

        createIFrameAndResolveMessage(url, isHidden).then(res => {
          submitPaymentWith3DSData(transactionId, res);
        });

        return;
      }

      if (response.gateway === 'BILL_1ST') {
        const merchantCode = threeDSResponse.merchantCode;
        const jwt = threeDSResponse.jwt;
        const transactionId = threeDSResponse.transactionId;
        const amount = threeDSResponse.amount;
        const currencyNumericCode = threeDSResponse.currencyNumericCode;
        const force = threeDSResponse.force;

        loadScript('https://secure3d.bill1st.com/js/v2/Bill1stSecure3D.js?profile=').then(() => {
          // eslint-disable-next-line no-undef
          const Secure = new Secure3D();
          Secure.setup(merchantCode, jwt);
          Secure.enableBinDetection('card-number');
          Secure.on('payments.validated', function (data) {
            submitPaymentWith3DSData(transactionId, data);
          });

          Secure.on('payments.noAction', function (data) {
            submitPaymentWith3DSData(transactionId, data);
          });

          Secure.on('payments.setupComplete', function () {
            try {
              const order = {
                Consumer: {
                  Email1: email.value,
                  BillingAddress: {
                    FullName: cardHolder.value,
                  },
                  Account: {
                    AccountNumber: cardNumber.value,
                    ExpirationMonth: cardExpiryMonth.value.toString().padStart(2, '0'),
                    ExpirationYear: cardExpiryYear.value,
                    CardCode: cardCode.value,
                    NameOnAccount: cardHolder.value,
                  },
                },
                OrderDetails: {
                  Amount: amount,
                  CurrencyCode: currencyNumericCode,
                },
              };
              console.log(order);
              if (force) {
                Secure.forceAuthentication(order);
              } else {
                Secure.do3D(order);
              }
            } catch (error) {
              redirectToRoute('/failure');
            }
          });

          Secure.on('payments.errorSetup', function () {
            redirectToRoute('/failure');
          });
        });
      } else {
        const threeDUrl = threeDSResponse.url;
        const preconditions = threeDSResponse.preconditions;
        const threeDUrlParams = threeDSResponse.params;
        if (threeDUrl != null && preconditions && preconditions.length) {
          Promise.all(
            preconditions.map((precondition, index) =>
              handlePrecondition({
                index,
                ...precondition,
              })
            )
          )
            .then(() => {
              createAndSendForm(threeDUrl, threeDUrlParams);
            })
            .catch(() => {
              redirectToRoute('/failure');
            });
        } else if (threeDUrl != null) {
          createAndSendForm(threeDUrl, threeDUrlParams);
        } else {
          redirectToRoute('/failure');
        }
      }
    };

    const handleResponse = response => {
      console.log('RESPONSE');
      if (response.status === 'SUCCESSFUL') {
        redirectToRoute('/success');
      } else if (response.status === 'PENDING') {
        handlePendingResponse(response);
      } else {
        redirectToRoute('/failure');
      }
    };

    onMounted(() => {
      if (defaultCountry) {
        country.value = defaultCountry.value;
      }
    });

    return {
      billingModel,
      cardNumber,
      cardHolder,
      cardCode,
      cardExpiryMonth,
      cardExpiryYear,
      email,
      submitPayment,
      name,
      surname,
      country,
      city,
      zipCode,
      address1,
      address2,
      allowedCountries,
      phoneNumber,
      instance,
      subscriptionPlan,
      areTheSameBillingModel,
      availableCurrencies,
      availableBillingModels,
      selectedCurrency,
      selectedTrialLength,
      availableTrailLengthForSelectedCurrency,
      selectedDaysInterval,
      availableDaysIntervalForSelectedCurrencyAndBillingModel,
      updateTrialLength,
      updateDaysInterval,
      updateCurrency,
      updateBillingModel,
      initialPeriodName,
      instanceAttributes,
    };
  },
};
</script>
